<template>
	<div class="printMain body-bg" v-loading="loading_load">
		<!-- 导入excel -->
		<!-- <el-dialog :title="$t('i18nn_17a941b7c4c108fe')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px" top="0"> -->
		<!-- <div class="top_action no_print"><el-button type="primary" icon="el-icon-printer" @click="printerAction()">{{$t('i18nn_886072e393710563')}}</el-button></div>
 -->
		<div class="top_action no_print">
			<el-checkbox v-model="showDet">{{$t('i18nn_78226fb265027602')}}</el-checkbox>
		</div>
		<div class="printer_block">
			<div class="printer_con">
				<div class="printer-logo"><img src="../assets/logo_en.png" width="80" height="auto" /></div>
				<h1 class="printer_tit" style="">
					<div style="">
						<div>Self-pickup Picking List</div>
					</div>
				</h1>

				<div style="font-size: 12pt; text-align: right; word-break: break-all;white-space:normal; "
					v-if="priterData && priterData[0] && priterData[0].print">
					<div style="margin:5px 0;">Warehouse#&nbsp;
						<span v-if='priterData[0].print.records && priterData[0].print.records[0]'>
							{{priterData[0].print.records[0].whNo}}
						</span>
					</div>
					<!-- <div style="margin:5px 0;">Date#&nbsp;<span>{{priterData[0].print.commitDate}}</span></div> -->
					<div style="margin:5px 0;">Customer#&nbsp;<span>{{priterData[0].print.cusName}}</span></div>
				</div>

				<!-- 合计 -->
				<div class="count-prt-con">
					<table class="simTable printerTable printerTableSummary" style="">
						<tr>
							<td colspan="4">
								<div class="titleItem">
									<strong>Summary</strong>
								</div>
							</td>
						</tr>
						<tbody class="text_small">
							<tr style="">
								<td colspan="4">
									<div class="titleItem">
										<span>总订单总数</span>:
										<strong>{{ summaData.orderTotal }}</strong>
									</div>
									<div class="titleItem">
										<span>总SKU种类</span>:
										<strong>{{ summaData.goodsSkuTotal }}</strong>
									</div>
									<div class="titleItem">
										<span>总件数</span>:
										<strong>{{ summaData.totalQT }}</strong>
									</div>
								</td>
								<!-- <td width="150">
									<span>Order&nbsp;Total:&nbsp;</span>
									<span>{{summaData.orderTotal}}</span>
								</td>
								<td width="150">
									
									<span>SKU&nbsp;Total:&nbsp;</span>
									<span>{{summaData.goodsSkuTotal}}</span>
								</td> -->
							</tr>
							<tr>
								<th>
									SKU
								</th>
								<th>
									Location * PSC<br />
									库位 * 拣货数
								</th>
								<th>
									Total PSC<br />
									总件数
								</th>
								<th>
									Total CTN<br />
									总箱数
								</th>
							</tr>
							<tr v-for="(item ,index) in summaData.goodsPlaceArr" :key="index" style="">
								<td width="150">
									<!-- <span>Sku:&nbsp;</span> -->
									<div><strong>{{item.goodsSku}}</strong></div>
									<div v-if="item.relationSkus && item.relationSkus.length>0">
										<span>
											别称
										</span>:
										<span>
											{{item.relationSkus.map(item2=>{
												return item2.relaGoodsSku
											}).join(',')}}
										</span>
									</div>
								</td>
								<!-- <td width="150">
									<span>{{item.goodsName}}</span>
								</td> -->
								<!-- <td width="150">{{item.placeArr}}</td> -->
								<td width="150">
									<div v-for="(item2,index2) in item.placeArr" :key="index2">
										<span>{{item2.place}}</span>&nbsp;*&nbsp;<strong class="text_big">{{item2.placeQT}}</strong>
									</div>
								</td>
								<td width="50">
									<div><strong>{{item.goodsSkuPCSTotal}}</strong></div>
								</td>
								<td width="50">
									<div><strong>{{item.goodsSkuCTNTotal}}</strong></div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<!-- 明细 -->
				<div  v-if="showDet">
					<div v-for="(itemP,indexP) in priterData" :key="indexP">
						<div style="" v-if="itemP.print">
					
							<!-- <div style="display: flex;justify-content: space-between; align-items: center; "> -->
							<!-- <barcode :code="itemP.print.sysNo" :options="{height:50}"></barcode> -->
							<!-- <div style="font-size: 12pt; text-align: right; word-break: break-all;white-space:normal; ">
									<div style="margin:5px 0;">Warehouse#&nbsp;
										<span v-if='itemP.print.records && itemP.print.records[0]'>
											{{itemP.print.records[0].whNo}}
										</span>
									</div>
									<div style="margin:5px 0;">Date#&nbsp;<span>{{itemP.print.commitDate}}</span></div>
									<div style="margin:5px 0;">Customer#&nbsp;<span>{{itemP.print.cusName}}</span></div>
								</div> -->
							<!-- </div> -->
					
							<!-- <div class="titleItem">
								<span>No.#</span>
								<strong style="font-size: 14pt;">{{ itemP.print.sendNo }}</strong>
							</div>
							<div class="titleItem">
								<span>Total CTN#</span>
								<strong>{{ itemP.print.totalCtn }}</strong>
							</div>
							<div class="titleItem">
								<span>Total PCS#</span>
								<strong>{{ itemP.print.totalCount }}</strong>
							</div> -->
					
							<table class="simTable printerTable">
					
								<tr>
									<td colspan="6">
										<div style="display: flex;">
											<div>
												<div class="titleItem">
													<strong>{{indexP+1}}.</strong>
												</div>
											</div>
											<div style="flex:1;">
												<div style="display: flex;">
													<div style="flex:1; line-height: 150%;">
														<div class="">
															<span>发货编号</span>:
															<strong>{{ itemP.print.sendNo }}</strong>
														</div>
														<!-- <div class="titleItem">
															<span>Date#</span>
															<strong>{{ itemP.print.commitDate }}</strong>
														</div> -->
														<div class="">
															<span>总箱数</span>:
															<strong>{{ itemP.print.totalCtn }}</strong>
														</div>
														
														<div class="">
															<span>总件数</span>:
															<strong>{{ itemP.print.totalCount }}</strong>
														</div>
														
														
													</div>
													<div class="" style="text-align: right">
														<barcode :code="itemP.print.sysNo" :options="{height:50}"></barcode>
													</div>
												</div>
												
					
												
												
												
					
					
					
					
												<!-- </h3> -->
												<!-- </div> -->
											</div>
										</div>
					
					
									</td>
								</tr>
					
								<!-- <tr> -->
									<!-- <td colspan="6"> -->
										<!-- <div class="" style="text-align: right">
											<barcode :code="itemP.print.sysNo" :options="{height:50}"></barcode>
										</div> -->
										
										<!-- <div class="titleItem">
											<span>No.#:</span>
											<strong>{{ itemP.print.sendNo }}</strong>
										</div> -->
										<!-- <div class="titleItem">
											<span>Total CTN#</span>
											<strong>{{ itemP.print.totalCtn }}</strong>
										</div>
										<div class="titleItem">
											<span>Total PCS#</span>
											<strong>{{ itemP.print.totalCount }}</strong>
										</div> -->
										<!-- <div class="titleItem">
											<span>Date#</span>
											<strong>{{ itemP.print.commitDate }}</strong>
										</div> -->
									<!-- </td> -->
								<!-- </tr> -->
					
								<tbody class="text_center">
									<tr class="gray_bg">
										<th width="200">
											SKU<br />
											SKU名称<br />
											入库编号
										</th>
										<th width="100">Picking Type<br />拣货类型</th>
										<th width="50">PCS<br />件数</th>
										<th width="50">CTN<br />箱数</th>
										<th width="50">CTN/PCS<br />每箱件数</th>
										<th width="100">Location * PSC<br />库位 * 拣货数</th>
									</tr>
								</tbody>
					
								<tbody v-for="(item, index) in itemP.print.records" :key="index">
									<tr class="text_center text_small">
										<td>
											<div>
												<div>
													<strong>{{item.goodsSku}}</strong>
												</div>
												
											</div>
											<div style="max-height:42pt; overflow:hidden;">
												{{item.goodsName}}
											</div>
											<!-- <div v-if="item.relationSkus && item.relationSkus.length>0">
												<span>
													别称
												</span>:
												<span>
													{{item.relationSkus.map(item2=>{
														return item2.relaGoodsSku
													}).join(',')}}
												</span>
											</div> -->
											<div v-if="!!item.packingNos && item.packingNos.length>0" style="border:1px solid #333;">
												Container#&nbsp;<span>{{item.packingNos.join(',')}}</span>
											</div>
										</td>
										<td>
											<div>
												<i class="el-icon-check" style="font-weight:bold;" v-if="'10'==item.plGoodsType"></i>
												<span>Pallet</span>
											</div>
											<div>
												<i class="el-icon-check" style="font-weight:bold;" v-if="'20'==item.plGoodsType"></i>
												<span>Box</span>
											</div>
										</td>
										<td>{{item.plCount}}</td>
										<td>{{item.sendCTN}}</td>
										<td>{{item.CTNPcs}}</td>
										<td>
											<span v-if="!!item.place">
												<span v-for="(item2,index2) in item.place" :key="index2">
													{{item2}}&nbsp;&nbsp<br />
												</span>
											</span>
										</td>
									</tr>
								</tbody>
					
								<tbody>
									<tr>
										<td colspan="6">
											<span>Remark#&nbsp;</span>
											<div class='pre-text'><strong>{{itemP.print.remark}}</strong></div>
										</td>
									</tr>
								</tbody>
							</table>
					
					
						</div>
					
					
						<!-- 增值服务 -->
					
						<div style="height: 10px;" class="no_print"></div>
						<div v-show="isShowValueAdded"
							v-if="itemP.print && itemP.print.workOrderDtos && itemP.print.workOrderDtos.length>0">
							<div class="printer_break"></div>
							<whPrinterValueAddListSub :openTime="ValueAddOpenTime" :tableData="itemP.print.workOrderDtos">
							</whPrinterValueAddListSub>
						</div>
					
					</div>
				</div>
				

				<div class="tableFooter">
					<ul>
						<li>Picking</li>
						<li>Labeling</li>
						<li>Check</li>
					</ul>
				</div>

			</div>
		</div>

	</div>
</template>
<script>
	import barcode from '@/components/Common/barcode.vue';
	import whPrinterValueAddListSub from '@/printer/whPrinterValueAddListSub.vue';
	export default {
		metaInfo() {
			return {
				title: this.title,
			}
		},
		props: {
			isShowValueAdded: {
				type: Boolean
			},
		},
		components: {
			barcode,
			whPrinterValueAddListSub
		},
		data() {
			return {
				title: "HYTX-Self-pickup-Packing-List",
				showDet: true,
				
				filterData: {
					"ids": [],
					"userId": "",
					"whNo": ""
				},
				loading_load: false,
				// priterAllData:[],
				priterData: [],
				summaData: {},
				ValueAddOpenTime: ''
			};
		},
		// watch: {
		//   openTime: function(newVal, oldVal) {
		//     console.log('openTime');
		//     this.dialogFile = true;
		//     this.initData();
		//   }
		// },
		//创建时
		created() {
			// this.getPageData();
			if (this.$route.query && this.$route.query.ids) {
				this.filterData.ids = this.$route.query.ids.split(",");
			}
			if (this.$route.query && this.$route.query.userId) {
				this.filterData.userId = this.$route.query.userId;
			}
			if (this.$route.query && this.$route.query.whNo) {
				this.filterData.whNo = this.$route.query.whNo;
			}
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.getPageData();
			},
			//打印
			// printerAction() {
			//   window.print();
			//   // this.$print(this.$refs.print);
			// },
			//请求分页数据
			getPageData() {
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.whPriterPickingList, {
						"ids": this.filterData.ids,
						"userId": this.filterData.userId,
						"whNo": this.filterData.whNo
					})
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						this.loading_load = false;
						if (200 == data.code && data.data) {
							this.summaData = data.data.summaTotal;
							//表格显示数据
							this.priterData = data.data.recorArr;
							//标题
							if (this.priterData && this.priterData.length > 0) {
								this.title = this.priterData.map((item) => {
									// if(item.print){
									return item.print && item.print.sendNo ? item.print.sendNo : '';
									// } else {
									// 	return "",
									// }
								}).join(',');
								this.title = this.title + "-Packing-List"
							}
							//增值服务
							let ValAddUpdateVal = 0;
							if (this.priterData && this.priterData.length > 0) {
								this.priterData.forEach(item => {
									if (item.print && item.print.workOrderDtos) {
										ValAddUpdateVal = ValAddUpdateVal + item.print.workOrderDtos.length;
									}

								});
							}
							if (ValAddUpdateVal > 0) {
								this.$emit('ValAddUpdate', {
									val: ValAddUpdateVal,
									show: true,
								});
							} else {
								this.$emit('ValAddUpdate', {
									val: 0,
									show: true,
								});
							}
							this.ValueAddOpenTime = new Date().getTime();

						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
	// @import '@/assets/css/printer.less'
	@import url(../assets/css/printer.less);
</style>